import { useEffect, useState } from 'react'
import { MdClose } from 'react-icons/md'
import IconBtn from './controls/IconBtn'
import MediaGallery from './MediaGallery'
import VideoPlayer from './VideoPlayer'
import '../css/Modal.css'

const Modal = ({ appState, setState }) => {
  const [mediaType, setMediaType] = useState('images')

  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp)
    const body = document.querySelector('body')
    body.style.overflow = 'hidden'
    return () => {
      document.removeEventListener('keyup', handleKeyUp)
    }
  })

  const closeModal = () => {
    const modal = document.querySelector('.modal')
    const body = document.querySelector('body')
    modal.classList.add('hide')
    setTimeout(
      () =>
        setState(state => ({
          ...state,
          modal: { isOpen: !state.modal.isOpen },
        })),
      1000,
    )
    if (body?.style) body.style['overflow-y'] = 'scroll'
  }

  const handleKeyUp = event => {
    event.key === 'Escape' && closeModal()
  }

  return (
    <aside className='modal' onKeyUp={handleKeyUp}>
      <h1> {appState?.work?.selected?.title?.toUpperCase() || ''}</h1>

      <div className='container-content'>
        {appState.work.selected && mediaType === 'images' && <MediaGallery {...{ appState }} />}
        {appState.work.selected && mediaType === 'video' && <VideoPlayer {...{ video: appState?.work?.selected?.media?.videos?.[0] }} />}
      </div>
      <button onClick={closeModal} className='close'>
        {
          <IconBtn
            {...{
              propOverrides: {
                isaction: 'true',
                path: 'MdClose',
                title: 'Close gallery',
              },
            }}
          />
        }
        {/* CLOSE */}
      </button>
      <div className='btn-area'>
        <button className={`btn-media left ${mediaType === 'images' && 'active '}`} onClick={() => setMediaType('images')}>
          IMAGES
        </button>
        <button className={`btn-media right reverse ${mediaType === 'video' && '  active  '}`} onClick={() => setMediaType('video')}>
          VIDEO
        </button>
      </div>
    </aside>
  )
}

export default Modal
