import '../css/Video.css'
const VideoPlayer = ({ video }) => {
  // console.log(video)
  return (
    <article className='container-video'>
      <video width='100%' height='100%' autoPlay controls muted className='controls'>
        <source src={video} type='video/mp4' />
        Your browser does not support the video tag.
      </video>
    </article>
  )
}

export default VideoPlayer
/*

  max-width: 70vw;
  height: auto;
  max-height: 70vh;

*/
