import '../../css/Animations.css'
import '../../css/CallToActionBtn.css'

const CallToActionBtn = ({ action, isHighlighted, text, to }) => {
  return (
    <a href={to || '#'} onClick={action} rel={to && 'noreferrer'} target={to && '_blank'} className={`btn hover-elevate-glow flip-in ${isHighlighted ? 'active light' : ''}`}>
      {isHighlighted && (
        <>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </>
      )}

      {text}
    </a>
  )
}

export default CallToActionBtn
