import {
  FaExternalLinkSquareAlt,
  FaImages,
  FaGithub,
  FaGithubSquare,
  FaListAlt,
} from 'react-icons/fa'
import { MdAlternateEmail } from 'react-icons/md'
import { TiSocialLinkedinCircular } from 'react-icons/ti'
import '../css/Contact.css'

const Contact = () => {
  return (
    <section className='contact'>
      {/* <div className="container"> */}
      <div className='iconRow'>
        <a
          href='https://github.com/ThomasMorgani'
          rel='noreferrer'
          target='_blank'
        >
          <FaGithub
            // path={FaGithub}
            title='Github profile'
            size={56}
            color='#bc13fe'
            className='iconBtn'
          />
        </a>
        <a href='mailto:contact@thomasmorgani.com'>
          <MdAlternateEmail
            // path={MdAlternateEmail}
            title='Email'
            size={75}
            color='#bc13fe'
            className='iconBtn'
          />
        </a>

        <a
          href='https://www.linkedin.com/in/thomas-morgani/'
          rel='noreferrer'
          target='_blank'
        >
          <TiSocialLinkedinCircular
            // path={TiSocialLinkedinCircular}
            title='Linkdin profile'
            size={69}
            color='#bc13fe'
            className='iconBtn'
          />
        </a>
      </div>

      {/* </div> */}
    </section>
  )
}

export default Contact
