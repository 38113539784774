import { useState } from 'react'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'

import '../css/Gallery.css'
import '../css/IconBtn.css'
const MediaGallery = ({ appState }) => {
  const work = appState.work.selected || null
  const [activeImage, setActiveImage] = useState(0)

  const nextImage = () => {
    setActiveImage(activeImage === work?.media?.images?.length - 1 ? 0 : activeImage + 1)
  }
  const prevImage = () => {
    setActiveImage(activeImage === 0 ? work?.media?.images?.length - 1 : activeImage - 1)
  }
  return (
    <section className='gallery'>
      <div className='control alignStart'>
        <MdChevronLeft
          // path={MdChevronLeft}
          title='Prev. image'
          size='4rem'
          color='#bc13fe'
          className='iconBtn  left'
          onClick={prevImage}
        />
      </div>
      <div className='container-image'>
        <img key={work?.media?.images?.[activeImage].title} src={work?.media?.images?.[activeImage]} alt={`${activeImage + 1}`} className='activeImage fadeIn' />
      </div>
      <div className='control alignEnd'>
        <MdChevronRight
          // path={MdChevronRight}
          title='Next image'
          size='4rem'
          color='#bc13fe'
          className='iconBtn right'
          onClick={nextImage}
        />
      </div>
    </section>
  )
}

export default MediaGallery
