import '../css/80sFloor.css'
import '../css/Hero.css'
import spaceship from '../images/spaceship-sprite.gif'
import sun from '../images/sun.png'
import ufo from '../images/ufo-sprite.gif'
import CallToActionBtn from './controls/CallToActionBtn'
const Hero = () => {
  const scrollToSelector = (e, selector) => {
    e.preventDefault()
    const el = document.querySelector(selector)
    if (el)
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      })
  }

  return (
    <section className='hero stars'>
      {/* <div className='name-box'> */}
      <img src={spaceship} alt='retro style sun rising.' className='spaceship'></img>
      <img src={sun} alt='retro style sun rising.' className='sun'></img>
      <img src={ufo} alt='retro style sun rising.' className='ufo'></img>
      <h1 data-text='THOMAS' className='name first_name metal raise shine'>
        THOMAS
      </h1>
      <h1 data-text='MORGANI' className='name last_name metal raise shine'>
        MORGANI
      </h1>
      {/* </div> */}
      {/* <div id="sect" className="accelerated"></div> */}
      <div id='retrobg-ground'>
        <div id='retrobg-linesWrap'>
          <div id='retrobg-lines'>
            <div id='retrobg-vlines'>
              <div className='retrobg-vline'></div>
              <div className='retrobg-vline'></div>
              <div className='retrobg-vline'></div>

              <div className='retrobg-vline'></div>
              <div className='retrobg-vline'></div>
              <div className='retrobg-vline'></div>
              <div className='retrobg-vline'></div>
              <div className='retrobg-vline'></div>
              <div className='retrobg-vline'></div>
              <div className='retrobg-vline'></div>
              <div className='retrobg-vline'></div>
              <div className='retrobg-vline'></div>
            </div>
            <div id='retrobg-hlines'>
              <div className='retrobg-hline'></div>
              <div className='retrobg-hline'></div>
              <div className='retrobg-hline'></div>
              <div className='retrobg-hline'></div>
              <div className='retrobg-hline'></div>
              <div className='retrobg-hline'></div>
              <div className='retrobg-hline'></div>
              <div className='retrobg-hline'></div>
            </div>
          </div>
        </div>
        <div id='retrobg-groundShadow'></div>
      </div>

      <section className='section-intro-copy'>Passionate, detail driven full-stack engineer; providing accessible, performant and UX focused web solutions for businesses.</section>
      <section className='section-cta'>
        {' '}
        <CallToActionBtn
          {...{
            text: 'RESUME',
            isHighlighted: false,
            to: 'https://shared.thomasmorgani.com/files/resume/Morgani_Thomas_Resume.pdf',
          }}
        ></CallToActionBtn>
        <CallToActionBtn
          {...{
            text: 'WORK',
            isHighlighted: true,
            action: e => scrollToSelector(e, '#works'),
          }}
        ></CallToActionBtn>
        <CallToActionBtn
          {...{
            text: 'CONTACT',
            isHighlighted: false,
            action: e => scrollToSelector(e, '.contact'),
          }}
        ></CallToActionBtn>
      </section>
    </section>
  )
}

export default Hero
