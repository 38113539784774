import { useEffect, useState } from 'react'
import IconBtn from './controls/IconBtn'
import '../css/WorkCard.css'
const WorkCard = ({ appState, openModal, work }) => {
  // console.log(work)
  // const { appState, setState } = useContext(AppContext)

  const [showExtendedDetails, setShowExtendedDetails] = useState(false)

  const onImageClick = e => {
    console.log(work)
    console.log(openModal)
    openModal(e, work)
  }

  const onShowExtendedDetails = e => {
    e.preventDefault()
    setShowExtendedDetails(!showExtendedDetails)
  }

  useEffect(
    work => {
      console.log('hi')
      if (!work) return
      const workCard = document.querySelector(`#card${work.id}`)
      //prevent hover from triggering animation
      workCard.classList && workCard.classList.remove('appear')
    },
    [work],
  )

  const image = work?.preview || work?.media?.images?.[0] || '/static/images/fallback.webp'
  console.log(image)
  return (
    <article id={`card${work.id}`} className='card appear'>
      <img
        alt={`preview  of ${work.title}`}
        className={`mediaContainer ${showExtendedDetails ? ' showExtendedDetails' : ''}`}
        src={image}
        onClick={onImageClick}
        // style={{ backgroundImage: `url(${image})` }}
      ></img>
      <div className={`detailContainer ${showExtendedDetails ? ' showExtendedDetails' : ''}`}>
        <h3 className='title'>{work.title || ''}</h3>
        <h5 className='tech'>{work.tech?.join(' • ') || 'example tech'}</h5>
        <p className='description'>{work.description}</p>
        <section className={`extendedDetails ${showExtendedDetails ? ' showExtendedDetails' : ''}`}>
          {work?.details?.length > 0 && work.details.map((d, i) => <p key={`ed${i}`}>{d}</p>)}
        </section>
      </div>

      <div className='icons'>
        <IconBtn
          key={'showExtendedDetails'}
          {...{
            propOverrides: {
              isaction: 'true',
              path: showExtendedDetails ? 'FaRegListAlt' : 'FaListAlt',
              target: '',
              title: 'Project details',
            },
            clickhandler: onShowExtendedDetails,
            work,
          }}
        ></IconBtn>
        {work.links &&
          work.links.map(link => {
            return (
              <IconBtn
                key={link.title}
                {...{
                  propOverrides: { ...link },
                  clickhandler: openModal,
                  work,
                }}
              ></IconBtn>
            )
          })}
      </div>
    </article>
  )
}

export default WorkCard
