import '../css/Works.css'
import WorkCard from './WorkCard'
import Contact from './Contact'

const Works = ({ appState, setState }) => {
  console.log(appState)

  const openModal = (e, work) => {
    if (e?.preventDefault) {
      e.preventDefault()
    }
    setState((state) => ({
      ...state,
      modal: { isOpen: !state.modal.isOpen },
      work: { items: [...state.work.items], selected: work },
    }))
  }
  return (
    <section className='content'>
      {/* <h1 className='header'>Work</h1> */}
      <div className='fade'></div>
      <div className='container' id='works'>
        {appState.work.items.map((work) => (
          <WorkCard key={work.id} {...{ appState, openModal, work }}></WorkCard>
        ))}
      </div>
      <div style={{ padding: '20px 10px', textAlign: 'center' }}>
        <h2>I am currently in the process of updating my projects section.</h2>
        <p>
          Please temporarily see my resume or github profile below for
          additional work.
        </p>
      </div>
      <Contact />
    </section>
  )
}

export default Works
