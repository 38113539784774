import {
  FaExternalLinkSquareAlt,
  FaGithubSquare,
  FaListAlt,
  FaRegListAlt,
} from 'react-icons/fa'
import { BsImageFill } from 'react-icons/bs'
import { MdClose } from 'react-icons/md'
// import { mdiImageMultiple, mdiGithub, mdiLink } from '@mdi/js'
// import * as mdiIcons from '@mdi/js'
import '../../css/IconBtn.css'

const IconBtnDefaults = {
  // color: '#c227fe',
  // height: '4rem',
  // icon: '',  //TODO: SET DEFAULT??
  // iconPath: '/static/icons/',
  // above: incorporate custom svgs, can remove mdi
  color: '#bc13fe',
  path: '',
  size: '2em',
  target: '#',
  title: '',
}

const iconMap = new Map([
  ['FaExternalLinkSquareAlt', FaExternalLinkSquareAlt],
  ['BsImageFill', BsImageFill],
  ['FaGithubSquare', FaGithubSquare],
  ['FaRegList', FaListAlt],
  ['FaRegListAlt', FaRegListAlt],
  ['MdClose', MdClose],
])

const IconBtn = ({ propOverrides = {}, clickhandler, work }) => {
  const Icon = iconMap.get(propOverrides?.path) || FaListAlt
  const iconBtnProps = { ...IconBtnDefaults, ...propOverrides }
  // const src = `${process.env.PUBLIC_URL}${iconBtnProps.iconPath}${iconBtnProps.icon}.svg`
  // const style = {
  //   color: iconBtnProps.color,
  //   height: iconBtnProps.height
  // }
  // console.log(src)
  // console.log(process.en)
  const handleClick = (e) => {
    if (propOverrides?.disabled) {
      e.preventDefault()
      return
    }
    if (propOverrides?.isaction) {
      e.preventDefault()
      clickhandler && clickhandler(e, work)
    }
  }
  return (
    // <img src={src} alt={IconBtn} style={style}></img>
    <a
      href={iconBtnProps.target}
      onClick={handleClick}
      target='_blank'
      rel='noreferrer'
      className={`icon iconBtn  ${propOverrides.disabled && ' disabled'}`}
    >
      <Icon {...{ ...iconBtnProps }} />
    </a>
  )
  //path={mdiLink} title='Media gallery' size={1.5} color='#bc13fe' onClick={openModal}
}

export default IconBtn
