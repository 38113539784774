const works = [
  {
    description: 'A feature rich reservation calendar, customer relation database and inventory management system written in Vue.js.',
    details: [
      `A complete solution to manage inventory, contacts, scheduling and media using a PHP restful API  and MySQL database on the backend.`,
      `Supports user roles and permissions, custom categories, tagging and field descriptions as well as theming and layout customization.`,
      `The app also supports a public plugin with modular control over displayed items, descriptions, availability and ability to make self reservations.`,
    ],
    github: 'https://github.com/ThomasMorgani/vueservations/tree/demo',
    homepage: 'www.vueservations.com',
    id: 1,
    links: [
      {
        isaction: 'true',
        path: 'BsImageFill',
        target: '',
        title: 'Media gallery',
      },
      {
        path: 'FaExternalLinkSquareAlt',
        target: 'https://www.vueservations.com/demo',
        title: 'Homepage',
      },
      {
        path: 'FaGithubSquare',
        target: 'https://github.com/ThomasMorgani/vueservations/tree/demo',
        title: 'Github repo',
      },
    ],
    media: {
      images: [
        '/static/images/ss/vueservations/vueservations1.png',
        '/static/images/ss/vueservations/vueservations2.png',
        '/static/images/ss/vueservations/vueservations3.png',
        '/static/images/ss/vueservations/vueservations4.png',
        '/static/images/ss/vueservations/vueservations5.png',
        '/static/images/ss/vueservations/vueservations6.png',
        '/static/images/ss/vueservations/vueservations7.png',
      ],
      videos: ['/static/videos/demos/vueservations1.mp4'],
    },
    tech: ['Vue.js', 'Vuex', 'Vue-Router', 'Vuetify', 'Codeigniter', 'MySQL'],
    title: 'Vueservations',
  },
  {
    description: 'Professional photography website with full photo and customer workflow management focusing on aesthetics and performance.',
    details: [
      'The application is split across multiple docker containers; an Express api , React client site and shared media endpoint. Uploaded images are optimized using Sharp, stored and served via Imgix image caching CDN. Other technologies used are Chakra-UI, Framer motion, handlebars, TanStack and Blurhash.',
      'Additional features include full media management console with  editing, tagging and drag/drop sorting, light/dark modes, 10 day weather applet, scheduling contact form with email confirmation and beautiful, performant image galleries with light box.',
    ],

    homepage: 'www.manuelpellon.com',
    id: 2,
    links: [
      {
        isaction: 'true',
        path: 'BsImageFill',
        target: '',
        title: 'Media gallery',
      },
      {
        path: 'FaExternalLinkSquareAlt',
        target: 'https://www.manuelpellon.com',
        title: 'Homepage',
      },
      {
        path: 'FaGithubSquare',
        target: '',
        title: 'Github repo',
      },
    ],
    media: {
      images: [
        '/static/images/ss/manuelpellon/manuelpellon1.png',
        '/static/images/ss/manuelpellon/manuelpellon2.png',
        '/static/images/ss/manuelpellon/manuelpellon3.png',
        '/static/images/ss/manuelpellon/manuelpellon4.png',
        '/static/images/ss/manuelpellon/manuelpellon5.png',
        '/static/images/ss/manuelpellon/manuelpellon8.png',
        '/static/images/ss/manuelpellon/manuelpellon6.png',
        '/static/images/ss/manuelpellon/manuelpellon7.png',
      ],
      videos: ['/static/videos/demos/manuelpellon-demo.mp4'],
    },
    preview: '/static/images/ss/manuelpellon/manuelpellon1.png',

    tech: ['React.js', 'Node.js', 'Express', 'SQLite', 'Sequelize'],
    title: 'Pellón Photography',
  },
  {
    description:
      'A full suite of services and clients for self-hosting fantasy football leagues; featuring real-time statistics and scoring, multiple game modes, custom leagues, chat, notifications and more. ',
    details: [
      'WOSFL aims to be a complete solution for creating fully customizeable fantasy football expriences.',
      'At its core WOSFL consists of a progressive web app client, PHP restful API, Node.js web scraper to aggregate game data, websocket server for live updates and PostgreSQL for the database.',
      'Additional noteable features include commissioner tools to manage league settings and data, user roles, historical data, full theming, chat with rooms,  message boards and over a dozen side pots and game modes.',
    ],

    github: 'https://github.com/ThomasMorgani/vueservations/tree/demo',
    homepage: 'www.vuelletinboard.com',
    id: 3,
    links: [
      {
        isaction: 'true',
        path: 'BsImageFill',
        target: '',
        title: 'Media gallery',
      },
      {
        path: 'FaExternalLinkSquareAlt',
        target: 'https://www.wosfl.com/login',
        title: 'Homepage',
      },
      {
        disabled: true,
        path: 'FaGithubSquare',
        target: 'https://github.com/ThomasMorgani/wosfl',
        title: 'Github repo is private',
      },
    ],
    media: {
      images: [
        '/static/images/ss/wosfl/wosfl1.png',
        '/static/images/ss/wosfl/wosfl2.png',
        '/static/images/ss/wosfl/wosfl3.png',
        '/static/images/ss/wosfl/wosfl4.png',
        '/static/images/ss/wosfl/wosfl5.png',
        '/static/images/ss/wosfl/wosfl6.png',
        '/static/images/ss/wosfl/wosfl7.png',
        '/static/images/ss/wosfl/wosfl8.png',
        '/static/images/ss/wosfl/wosfl9.png',
        '/static/images/ss/wosfl/wosfl10.png',
        '/static/images/ss/wosfl/wosfl11.png',
        '/static/images/ss/wosfl/wosfl12.png',
        '/static/images/ss/wosfl/wosfl13.png',
        '/static/images/ss/wosfl/wosfl99.png',
      ],
      videos: ['/static/videos/demos/wosfl1.mp4'],
    },
    preview: '',

    tech: ['Vue.js', 'Node.js', 'Codeigniter', 'PostgreSQL', 'Socket.io'],
    title: 'WOSFL',
  },
  {
    description:
      'A frontend interface to organize, monitor, send and track NFTs by creating custom collections, watching addresses or connecting a variety of supported Ethereum Layer 2 wallets. ',
    details: [
      'Nfty-Trckr enables users to completely manage all things NFT across a wide breadth of blockchains including Ethereum Mainnet (L1), IMX, Loopring and polygon.',
      'Through the collection system, users can organize an unlimited number of NFTs into how they see fit and use the tracking mechanism to monitor what wallets hold the assets.',
      'Wallet monitoring and batch sending creates an easy way to distribute NFT collections to multiple, predetermined recipients. ',
    ],

    github: 'https://github.com/ThomasMorgani/NFTy-Trckr',
    homepage: 'https://www.nfty-trckr.com',
    id: 4,
    links: [
      {
        isaction: 'true',
        path: 'BsImageFill',
        target: '',
        title: 'Media gallery',
      },
      {
        path: 'FaExternalLinkSquareAlt',
        target: 'https://www.nfty-trckr.com',
        title: 'Homepage',
      },
      {
        path: 'FaGithubSquare',
        target: 'https://github.com/ThomasMorgani/NFTy-Trckr',
        title: 'Github repo',
      },
    ],
    media: {
      images: [
        '/static/images/ss/nfty-trckr/nfty-trckr1.png',
        '/static/images/ss/nfty-trckr/nfty-trckr2.png',
        '/static/images/ss/nfty-trckr/nfty-trckr3.png',
        '/static/images/ss/nfty-trckr/nfty-trckr4.png',
        '/static/images/ss/nfty-trckr/nfty-trckr5.png',
        '/static/images/ss/nfty-trckr/nfty-trckr8.png',
        '/static/images/ss/nfty-trckr/nfty-trckr6.png',
        '/static/images/ss/nfty-trckr/nfty-trckr7.png',
        '/static/images/ss/nfty-trckr/nfty-trckr8.png',
        '/static/images/ss/nfty-trckr/nfty-trckr9.png',
      ],
      videos: ['/static/videos/demos/nfty-trckr1.mp4'],
    },
    preview: '/static/images/ss/nfty-trckr/nfty-trckr4.png',

    tech: ['Vue.js', 'Nuxt', 'LoopringSDK', 'WalletConnect', 'Web3.js'],
    title: 'NFTy-Trckr',
  },
  {
    description: 'A complete digital bulletinboard system with custom theming, layouts, media formats, content schedules and user access controls written in Vue.js.',
    details: [
      'Vuelletinboard provides a centralized administration panel to control an unlimited amount of digital bulletin displays with unique content, components, layouts, scheduling and theming.',
      'User roles and permissions allow delegation of responsibilites on a per group or station basis.',
      'Displays can be configured for a wide range of screen sizes and interactability with configurable polling for live monitoring and updates.',
    ],

    github: 'https://github.com/ThomasMorgani/vueservations/tree/demo',
    homepage: 'www.vuelletinboard.com',
    id: 5,
    links: [
      {
        isaction: 'true',
        path: 'BsImageFill',
        target: '',
        title: 'Media gallery',
      },
      {
        path: 'FaExternalLinkSquareAlt',
        target: 'https://www.vuelletinboard.com/login',
        title: 'Homepage',
      },
      {
        path: 'FaGithubSquare',
        target: 'https://github.com/ThomasMorgani/Vuelletinboard/tree/demo',
        title: 'Github repo',
      },
    ],
    media: {
      images: [
        '/static/images/ss/vuelletinboard/vuelletinboard1.png',
        '/static/images/ss/vuelletinboard/vuelletinboard2.png',
        '/static/images/ss/vuelletinboard/vuelletinboard3.png',
        '/static/images/ss/vuelletinboard/vuelletinboard4.png',
        '/static/images/ss/vuelletinboard/vuelletinboard5.png',
        '/static/images/ss/vuelletinboard/vuelletinboard8.png',
        '/static/images/ss/vuelletinboard/vuelletinboard6.png',
        '/static/images/ss/vuelletinboard/vuelletinboard7.png',
      ],
      videos: ['/static/videos/demos/vuelletinboard1.mp4'],
    },
    preview: '/static/images/ss/vuelletinboard/preview.png',

    tech: ['Vue.js', 'Vuex', 'Vue-Router', 'Vuetify', 'Codeigniter', 'MySQL'],
    title: 'vuelletinboard',
  },
]

export default works
